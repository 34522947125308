// import Background from '../../asses'



.login-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100vh;
    align-items: center;

@media screen and (max-width:768px) {
flex-direction: column;    
}

    .panel1 {
        // background-image: linear-gradient(90deg, #7435FF 0%, #306BFF 99.4%, #306BFF 100%);
        background-color: #7435FF;
        background-image: url("../../assets/images/Union 1.svg");
        width: 50%;
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;


        @media screen and (max-width:768px) {
            width: 100%;
            height: 33vh;
        }
    }

    .panel2 {
        width: 50%;
        height: 100vh;

        @media screen and (max-width:768px) {
            width: 100%;
            height: 70vh;
        }
        @media screen and (max-width:480px) {
            background-color: #ffff;
            border-radius: 24px;
            margin-top: -2.5vh;
        }
    }
}


.login-container-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

//     @media screen and (max-width:480px) {
// align-items: none;
// margin-top: 25px;
// }


    .body-1 {
        width: 450px;

        @media screen and (max-width:1024px) {
            width: 400px;
        }

        
    @media screen and (max-width:480px) {
        width: 350px;
        margin-bottom: 100px;
    }
      

        .title-page {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }

        .form-details {
            margin-top: 20px;

            .heading-title-login {
                font-size: 24px;
                font-weight: 700;
                letter-spacing: -0.24px;
            }

            .login-subheading-text {
                color: var(--v-2-grey, #58596C);
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -0.14px;
            }
        }

        .login-form-container {
            margin-top: 25px;
            .login-form-input{
                height: 50px;
            }
        }
    }

    .login-content {
        text-wrap: wrap;
        width: 571px;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;

        @media screen and (max-width:1024px) {
            width: 400px;
            padding: 10px;
        }

        
    @media screen and (max-width:480px) {
        width: 350px;
        margin-top: 10px;
        text-align: center;
        line-height: 1.4em;
    }

    }

    .avatar-name {
        color: #ffffff;
        font-size: 20px;

    }
}
.login-create-account{
    margin-top: 20px;
    width: 100%;
}

.create-btn
{
    font-size: 12px;
    font-weight: 500;
}

.forgot-goback{
    margin-top: 20px;
    width: 100%;  
float: right;
text-align: center;
justify-content: center;
align-items: center;
}

// .avatar{
//       display: flex;
//       flex-direction: row;
  

//     @media screen and (max-width: 480px) {
//         justify-content: center;
//           }
// }


.avatar{

    display: flex;
    align-items: center;

//   display: flex;
//   flex-direction: row;
//   margin-top: 30px;
//   margin-right: 160px;
  
//   @media screen and (max-width: 576px) {
//     margin-right: 89px;
//   }
  @media screen and (max-width: 480px) {
    gap: 5px;
    margin-left: 68px;
    line-height: 1.2em; /* 21.397px */

  }
  
}    // margin-right: 150px;

.avatar-name{
font-size: 24px;
margin-top: 2px;
padding: 15px;
@media screen and (max-width: 480px) {
  font-size: 18px;

}
}

.avatar-profile{
    width: 62px;
height: 62px;
margin-top: 5px;
@media screen and (max-width: 480px) {
    width: 54px;
    height: 54px;  
  }
}
.password-icon{
    color: #9E9FAB;
    font-size: 24px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    i{
        font-size: 16px;
    }
}