@import '../common.scss';
// .forgot-wrapper {
//     background: $body-bg;
//     min-height: 100vh; 
//     .forgot-panel {
//         background-color: $white;
//         padding: 15px;
//     }
//     h4 {
//         font-size: 18px;
//         margin-bottom: 25px;
//     }
//     .forgot-lock-icon {
//         background: $grey-color;
//         border-radius: 3px 0px 0px 3px;
//         border: 1px solid $grey-color;
//         padding: 5.5px;
//         text-align: center;
//     }
//     .forgot-form-input {
//         border-radius: 0px 3px 3px 0px;
//     }
//     .forgot-form-input:hover {
//         border-color: $green-btn-border;
//     }
//     .forgot-form-input:focus {
//         border-color: $green-btn-border;
//     }
//     .input-error {
//         color: $error-red;
//         border: 1px solid $error-red;
//         border-radius: 0px 3px 3px 0px;
//       }
//     .forgot-form-input-error {
//         color: $error-red;
//         border-radius: 0px 3px 3px 0px;
//     }
//     .forgot-form-input-error:hover {
//         border: 1px solid $error-red;
//     }
//     .forgot-form-input-error:focus {
//         border: 1px solid $error-red;
//     }
//     .forgot-goback {
//         @include default-btn-style;
//     }
//     .forgot-goback:hover {
//         background-color: #cbd2db;
//     }
//     .forgot-send-email {
//         @include green-btn-style;
//     }
//     .forgot-send-email:hover {
//         background-color: #357360;
//     }
// }
.forgot-wrapper {
    background: #ECF5FF !important;
    min-height: 100vh; 
    .forgot-panel {
        background-color: $white;
        padding: 15px 35px !important;
        min-width: 400px !important;
        height: 400px;
        box-shadow: 0px 0px 21px 2px rgba(12, 9, 27, 0.07);
    }
    h4 {
        font-size: 24px;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .forgot-lock-icon {
        background: $grey-color;
        border-radius: 3px 0px 0px 3px;
        border: 1px solid $grey-color;
        padding: 5.5px;
        text-align: center;
    }
    .forgot-form-input {
        border-radius: 4px;
        height: 48px;
        border-color: 1px solid #E7E7EA !important;
    }
    .forgot-form-input:hover {
        border-color: 1px solid #E7E7EA !important;
    }
    .forgot-form-input:focus {
        border-color: 1px solid #E7E7EA !important;
    }
    .input-error {
        color: $error-red;
        border: 1px solid $error-red;
        border-radius: 4px;
      }
    .forgot-form-input-error {
        color: $error-red;
        border-radius: 4px;
        height: 50px;
    }
    .forgot-form-input-error:hover {
        border: 1px solid $error-red;
        border-radius: 4px;
        height: 50px;
    }
    .forgot-form-input-error:focus {
        border: 1px solid $error-red;
        border-radius: 4px;
        height: 50px;
    }
    .forgot-goback {
        // @include default-btn-style;
        width: 100%;
        height: 48px;
        border-radius: 4px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .forgot-goback:hover {
        background-color: #cbd2db;
    }
    .forgot-send-email {
        // @include green-btn-style;
        background-color: #0075FF !important;
        border-color: #0075FF; 
        width: 100% !important;
        height: 48px !important; 
        border-radius: 4px;
        margin-top: 30px;
        margin-bottom: 12px;
    }
    .forgot-send-email:hover {
        background-color: #0075FF !important;
        opacity: 0.8;
    }
    .forget-password-msg{
        // margin-top: 15px !important;
        margin-bottom: 40px !important;
    }
    @media screen and (max-width: 768px) {
        .forgot-panel {
            min-width: 390px !important;
        }
        }
        .ant-btn-primary:hover, .ant-btn-primary:focus{
            background-color: #0075FF !important;
        }
}